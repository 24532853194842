export default [
  {
    path: "/products/",
    text: "products",
  },
  // {
  //   path: "/blog",
  //   text: "blog",
  // },
  {
    path: "/blogs/",
    text: "blogs",
  },
]
