import React from "react"
import { MdHome, MdEmail } from "react-icons/md"

export default [
  {
    icon: <MdEmail title="Contact" />,
    path: "/contact/",
  },
  {
    icon: <MdHome title="Home" />,
    path: "/",
  },
]
